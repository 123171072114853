<ng-container *transloco="let t">
  <!-- Add focus on body so that flatpickr isn't shown on open -->
  <div class="modal-body" ngbAutoFocus>
    <div class="text-secondary smaller">CLOCK OVERRIDE</div>
    <input #calendar type="text" class="form-control" [(ngModel)]="now" (ngModelChange)="updateNow()"/>
  </div>

  <div class="mb-3 d-flex">
    <button type="button" class="ms-auto btn btn-sm btn-outline-secondary" (click)="cancel()">Cancel</button>
    <button type="button" class="ms-2 me-3 btn btn-sm btn-outline-primary" (click)="apply()"
            [disabled]="false">Apply</button>
  </div>
</ng-container>
