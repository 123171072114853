import { Injectable } from '@angular/core';

export type ToastLevel = 'info' | 'warn';
export type Toast = { id: string; level: ToastLevel; title: string; message: string; };

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: Toast[] = [];

  constructor() {
    (window as any).toast = this;
  }

  info(title: string, message: string): void {
    this.show('info', title, message)
  }

  warn(title: string, message: string): void {
    this.show('warn', title, message)
  }

  private show(level: ToastLevel, title: string, message: string) {
    const toast = { id: self.crypto.randomUUID(), level, title, message };
    // Usually toast is shown after API call. wait a bit for overlay to be gone
    setTimeout(() => this.toasts.push(toast), 200);
    setTimeout(() => this.remove(toast), 5000);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t.id !== toast.id);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
