<ng-container *transloco="let t">
  <div class="dropdown dropdown-sm" *ngIf="fUser">
    <ng-container *ngIf="fUser.photoURL">
      <img class="user rounded-circle clickable"
           referrerpolicy="no-referrer"
           src="{{fUser.photoURL}}" data-bs-toggle="dropdown"/>
    </ng-container>
    <ng-container *ngIf="!fUser.photoURL">
      <div class="user clickable d-flex align-items-center justify-content-center rounded-circle bg-info" data-bs-toggle="dropdown">
        <fa-icon class="text-white-50" [icon]="faUser"></fa-icon>
      </div>
    </ng-container>

    <div class="dropdown-menu">
      <ng-container *ngIf="user">
        <div class="dropdown-item-text">
          <div class=" font-xs text-muted text-uppercase">
            {{ t('user.email') }}
          </div>
          <div>
            {{user.email}}
          </div>
        </div>

        <div class="dropdown-item-text" *ngIf="user.organizations.length > 1">
          <div class=" font-xs text-muted text-uppercase">
            {{ t('user.organization') }}
          </div>
          <select class="form-select form-select-sm" [ngModel]="user.organization" (change)="selectOrganization($any($event.target).value)">
            <ng-container *ngFor="let org of user.organizations">
              <option [disabled]="org === user.organization">{{ org }}</option>
            </ng-container>
          </select>
        </div>

        <div class="dropdown-item-text" *ngIf="isProgramManager(user)">
          <div class="form-check form-switch">
            <input class="form-check-input clickable" type="checkbox" [(ngModel)]="preview"/>
            <label>{{ t('user.preview') }}</label>
          </div>
          <div class="mt-1 form-check form-switch">
            <input class="form-check-input clickable" type="checkbox" [disabled]="!preview" [(ngModel)]="draft"/>
            <label>{{ t('user.draft') }}</label>
          </div>
        </div>

        <div class="dropdown-item-text" *ngIf="shouldShowParams()">
          <button class="btn btn-sm btn-outline-secondary" (click)="setParams()">Set params</button>
        </div>

        <div class="dropdown-divider"></div>
        <div class="dropdown-item clickable">
          <span (click)="updatePassword()">{{ t('user.password') }}</span>
        </div>

        <div class="dropdown-divider"></div>
        <span class="dropdown-item clickable" (click)="logout()">{{ t('user.logout') }}</span>

      </ng-container>
    </div>
  </div>
</ng-container>
