import { DateTime } from 'luxon';
import { interval, map, Observable } from 'rxjs';


export class FakeClock {
  private readonly diff = this.initialTime.diffNow();

  constructor(private readonly initialTime: DateTime) {
  }

  get currentTime$(): Observable<string> {
    return interval(100)
      .pipe(
        map(() => this.nowString())
      );
  }

  now(): string {
    return this.nowString();
  }

  private nowString(): string {
    return DateTime.utc().plus(this.diff).set({ millisecond: 0}).toISO({ suppressMilliseconds: true });
  }
}
