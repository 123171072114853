import { Injectable } from '@angular/core';
import { from, map, Observable, tap } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TrpcService } from './trpc.service';

type Theme = {
  title: string;
  subtitle: string;
  logo: SafeHtml;
  style: string;
};

@Injectable({
  providedIn: 'root'
})
export class CustomThemeService {
  private theme: Theme = {
    ...DEFAULT_THEME,
    logo: this.sanitizer.bypassSecurityTrustHtml(DEFAULT_THEME.logo),
    style: ''
  };

  constructor(private trpc: TrpcService,
              private sanitizer: DomSanitizer) {

    this.loadTheme()
      .pipe(
        tap(theme => this.theme = theme ?? this.theme)
      )
      .subscribe();
  }

  private loadTheme(): Observable<Theme | undefined> {
    return from(this.trpc.client.organization.get.query())
      .pipe(
        map(loaded => {
          if (loaded.theme) {
            return {
              title: loaded.theme.title,
              subtitle: loaded.theme.subtitle,
              logo: this.sanitizer.bypassSecurityTrustHtml(`<img src="${loaded.theme.logo}"/>`),
              style: loaded.theme.style
            }
          }

          return undefined;
        })
      );
  }

  public get homeTitle(): string {
    return this.theme.title;
  }

  public get homeSubTitle(): string {
    return this.theme.subtitle;
  }

  public get logo(): SafeHtml| undefined {
    return this.theme.logo;
  }

  public get style(): string {
    return this.theme.style;
  }
}

const DEFAULT_THEME = {
  title: 'Learnswell',
  subtitle: 'The fastest way to learn a new skill',
  logo: `
<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 2000 397.59">
  <g>
    <path d="M443.85,258.3v-139h20.31V238h84.65v20.3h-105Z" class="fill-primary"></path>
    <path d="M581.06,258.3v-139H695.54v20.5H601.37v36.35h47.36v20.15H601.37V238h97.69v20.3h-118Z" class="fill-primary"></path>
    <path d="M840,258.3l-20-45.3H757.61l-20.7,45.3h-22l63.21-138.85H800.5L862.77,258.3ZM789,144.06l-22,48.47h44Z" class="fill-primary"></path>
    <path d="M988.64,200l28.88,58.33H994.59l-28-57.59H914.79V258.3H894.48v-139H976.9c25.36,0,43.83,14.56,43.83,41,0,21-11.93,34.86-32.05,39.71ZM976.9,139.4H914.83v41H976.9c13.82,0,23.68-6.53,23.68-21.24S990.13,139.4,976.9,139.4Z" class="fill-primary"></path>
    <path d="M1168.57,258.3l-83.91-105.87V258.3h-20v-139h19.76l83.71,106.23V119.29h20.51v139h-20.16Z" class="fill-primary"></path>
    <path d="M1294.25,261.47c-28.34,0-49.59-8.21-69.56-28.52l14.17-13.62C1256.75,235.92,1272.8,241,1297,241c15.45,0,43.06-4.85,43.06-25,0-6.89-3.57-12.13-9.33-15.85-7.28-4.65-14.16-5-34.67-5.4l-20.86-.39c-21.64-.39-41.41-11.73-41.41-35.6,0-20.31,17-33.92,34.68-39.71a81.49,81.49,0,0,1,24-3.56,95.22,95.22,0,0,1,57.81,19l-11.4,16.2c-15.11-9.31-25.71-14.56-43.83-14.56-13.61,0-40.82,4.31-40.82,22.38,0,13.62,12.48,16.05,23.48,16.05h20.71c15.46,0,28.14.19,42,8.37A39.53,39.53,0,0,1,1360,217.61c0,33.73-38.59,43.82-65.83,43.82Z" class="fill-primary"></path>
    <path d="M1522.64,258.3h-20.85l-28.34-98.4-27.4,98.4h-22l-41.56-139h20.85l31.32,103.06,28.53-103.06H1483l29.26,103.06L1543,119.25h21.25L1522.69,258.3Z" class="fill-primary"></path>
    <path d="M1597,258.3v-139h114.47v20.5h-94.16v36.35h47.35v20.15h-47.35V238H1715v20.3H1597Z" class="fill-primary"></path>
    <path d="M1756.83,258.3v-139h20.32V238h84.65v20.3h-105Z" class="fill-primary"></path>
    <path d="M1895,258.3v-139h20.31V238H2000v20.3H1895Z" class="fill-primary"></path>
    <path d="M295.09,382.88V368.17h-68V311.32h54v71.56h14v0h14v-101H199.17V397.59h109.9V382.88Z" class="fill-secondary"></path>
    <path d="M95.92,101V86.27h-68V29.42H82V101h14v0h14V0H0V115.73H109.89V101Z" class="fill-secondary"></path>
    <path d="M70.59,114.52H39.32V354.2H70.59Z" class="fill-secondary"></path>
    <path d="M203,325.25H41.2V354.2H203Z" class="fill-secondary"></path>
  </g>
</svg>
`
};

