<ng-container *transloco="let t">
  <div class="modal-body">
    <div *ngIf="!hasLength || !isSame" @fadeInOut>
      <div class="mb-2 text-danger small" *ngIf="!hasLength" @fadeIn>
        <fa-icon class="me-2" [icon]="faExclamationTriangle"/>{{ t('set-password-modal.error.length') }}
      </div>

      <div class="mb-2 text-danger small" *ngIf="hasLength && !isSame" @fadeIn>
        <fa-icon class="me-2" [icon]="faExclamationTriangle"/>{{ t('set-password-modal.error.diff') }}
      </div>
    </div>

    <div>
      <div class="font-xs text-secondary mb-1 text-uppercase">{{ t('set-password-modal.label.password') }}</div>
      <input type="password" class="form-control" [(ngModel)]="password" (ngModelChange)="validate()"/>
    </div>
    <div class="mt-2">
      <div class="font-xs text-secondary mb-1 text-uppercase">{{ t('set-password-modal.label.confirmation') }}</div>
      <input type="password" class="form-control" [(ngModel)]="confirmation" (ngModelChange)="validate()"/>
    </div>
  </div>

  <div class="mb-3 d-flex">
    <button type="button" class="ms-auto btn btn-sm btn-outline-secondary" (click)="cancel()">{{ t('common.button.cancel') }}</button>
    <button type="button" class="ms-2 me-3 btn btn-sm btn-outline-primary" [disabled]="!hasLength || !isSame" (click)="confirm()">{{ t('common.button.ok') }}</button>
  </div>
</ng-container>
