import { AfterViewInit, Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoDirective } from '@ngneat/transloco';
import flatpickr from 'flatpickr';
import minMaxTimePlugin from 'flatpickr/dist/plugins/minMaxTimePlugin';

import { StateService } from '../../services/state.service';
import { filter, Subscription, take, tap } from 'rxjs';
import { ToastService } from '../../shared/toast/toast.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateTime } from 'luxon';
import { FakeClock } from '../../utils/fake-clock';

@Component({
  selector: 'upskill-custom-params-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, TranslocoDirective],
  templateUrl: './custom-params-modal.component.html',
  styleUrls: ['./custom-params-modal.component.scss'],
})
export class CustomParamsModalComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar!: ElementRef;
  private destroyRef = inject(DestroyRef);

  now = '';

  allowDismiss = true;

  private clockSubscription: Subscription | undefined;

  constructor(private activeModal: NgbActiveModal,
              private toast: ToastService,
              private state: StateService) {
  }

  ngOnInit(): void {
    this.state.select(state => state.params)
      .pipe(
        take(1),
        filter(params => !!params.clock),
        tap(params => {
          this.clockSubscription = params.clock!.currentTime$
            .pipe(
              takeUntilDestroyed(this.destroyRef),
              tap(now => this.now = now)
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    const minMaxTime = minMaxTimePlugin({
      getTimeLimits: (date)=> {
        const utcSelected = DateTime.fromJSDate(date).toUTC();
        const now = DateTime.utc();

        if (utcSelected.toISODate() === now.toISODate()) {
          return {
            minTime: `00:00`,
            maxTime: `${now.get('hour')}:${now.get('minute')}`
          };
        }

        return {
          minTime: '00:00',
          maxTime: `23:59`,
        }
      }
    });

    // flatpickr uses local time convert date to UTC in disable/minMaxTime
    flatpickr(
      this.calendar!.nativeElement,
      {
        enableTime: true,
        dateFormat: 'Y-m-d\\TH:i:S\\Z',
        disable: [date => DateTime.fromJSDate(date).toUTC() > DateTime.utc()],
        plugins: [minMaxTime],
        onOpen: () => this.allowDismiss = false,
        onClose: () => setTimeout(() => this.allowDismiss = true, 1000)
      });
  }

  cancel(): void {
    this.activeModal.close();
  }

  updateNow(): void {
    this.clockSubscription?.unsubscribe();
  }

  apply(): void {
    if (this.now === '') {
      this.state.setState({ params: { clock: undefined } });
      this.toast.info('Updated override params', `Cleared clock override`);
    } else {
      this.state.setState({ params: { clock: new FakeClock(DateTime.fromISO(this.now)) } });
      this.toast.info('Updated override params', `Updated clock override to ${this.now}`);
    }
    this.cancel();
  }
}
