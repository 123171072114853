import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { fadeIn, fadeInOut } from '../animations';
import { TranslocoDirective } from '@ngneat/transloco';


@Component({
  selector: 'buildswell-set-password-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, TranslocoDirective],
  templateUrl: './set-password-modal.component.html',
  styleUrls: ['./set-password-modal.component.scss'],
  animations: [fadeInOut, fadeIn]
})
export class SetPasswordModalComponent {
  protected readonly faExclamationTriangle = faExclamationTriangle;

  password = '';
  confirmation = '';

  hasLength = false;
  isSame = true;

  constructor(private activeModal: NgbActiveModal) {
  }

  cancel(): void {
    this.activeModal.close();
  }

  confirm(): void {
    this.activeModal.close(this.password);
  }

  validate(): void {
    this.hasLength = this.password.length >= 6;
    this.isSame = this.password === this.confirmation;
  }
}
