import { animate, AnimationTriggerMetadata, AUTO_STYLE, style, transition, trigger } from '@angular/animations';

// Add this trigger to parent element to prevent :enter animation on page load
// Animation on child element is blocked while animation on parent is being run
export const disablePageLoadAnimation: AnimationTriggerMetadata = trigger('disablePageLoadAnimation', [
  transition(':enter', [])
]);

export const fadeIn: AnimationTriggerMetadata = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 }))
  ]),
]);

export const fadeOut: AnimationTriggerMetadata = trigger('fadeOut', [
  transition(':leave', [
    animate(300, style({ opacity: 0 }))
  ])
]);

export const fadeOutSlow: AnimationTriggerMetadata = trigger('fadeOutSlow', [
  transition(':leave', [
    animate(1000, style({ opacity: 0 }))
  ])
]);

export const fadeInOut: AnimationTriggerMetadata = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate(300, style({ opacity: 0 }))
  ])
]);

export const fadeFastInOut: AnimationTriggerMetadata = trigger('fadeFastInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(100, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate(300, style({ opacity: 0 }))
  ])
]);

export const fadeSlide: AnimationTriggerMetadata = trigger('fadeSlide', [
  transition(':leave', [
    animate('300ms ease-in', style({ transform: 'translateX(20%)', opacity: 0 }))
  ])
]);

export const fadeSlideUp: AnimationTriggerMetadata = trigger('fadeSlideUp', [
  transition(':leave', [
    animate('300ms ease-in', style({ transform: 'translateY(-20%)', opacity: 0 }))
  ])
]);

export const slideUpDown: AnimationTriggerMetadata = trigger('slideUpDown', [
  transition(':enter', [
    style({ transform: 'translateY(-20%)' }),
    animate(300, style({ transform: 'translateY(0%)' }))
  ]),
  transition(':leave', [
    animate(300, style({ transform: 'translateY(-20%)' }))
  ])
]);

export const fadeSlideInInOutOut: AnimationTriggerMetadata = trigger('fadeSlideInInOutOut', [
  transition(':enter', [
    style({ transform: 'translateX(100%)', opacity: 0 }),
    animate(300, style({ transform: 'translateX(0%)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0%)', opacity: 1 }),
    animate(300, style({ transform: 'translateX(100%)', opacity: 0 }))
  ])
]);

export const collapse = trigger('collapse', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    animate('100ms ease-out', style({ height: AUTO_STYLE }))
  ]),
  transition(':leave', [
    style({ height: AUTO_STYLE, overflow: 'hidden'}),
    animate('100ms ease-in', style({ height: 0 }))
  ]),
])
